// FORM INPUT
@import "core";

@include respond-to(base-inline) {
  :where(.tc_form__input){display:block;width:100%;height:var(--form-input-height);font-size:var(--form-input-font-size);background-color:var(--color-light);border:var(--spacing-1) solid var(--color-dark-500);border-radius:var(--spacing-1);padding:0 var(--form-input-label-padding);color:var(--color-text);appearance:none;box-sizing:border-box;
    &:focus{border-color:var(--color-500);}
    &:invalid{box-shadow:none;}
    &.invalid{border:var(--spacing-1) solid var(--color-error);}
    &::placeholder{opacity:0;transition:opacity var(--transition-slow);color:var(--color-400);}
    &.tc_form__input--with-button{padding-right:var(--form-input-height);}
    &.tc_form__input--with-error{border-color:var(--color-error);}
    &[readonly],&[disabled]{background-color:var(--color-200);}
    &[disabled]{color:var(--color-500);-webkit-text-fill-color:var(--color-500);pointer-events:none;}
    &[type="file"]{color:var(--color-600);
      &::file-selector-button{margin-top:var(--spacing-8);color:var(--color-link);border:0;background-color:transparent;padding-left:0;text-decoration:underline;cursor:pointer;}
    }

    // search
    &[type="search"]{padding-left:calc(var(--spacing-20) + var(--spacing-8) + var(--form-input-label-padding));border-radius:calc(var(--form-input-height) / 2);border:0;
      &.tc_form__input--light{background-color:var(--color-100);}
      &::-webkit-search-cancel-button{display:none;}
    }
  }

  :where(.tc_form__group){
    .tc_form__svg{position:absolute;left:var(--form-input-label-padding);top:0;bottom:0;margin:auto;width:var(--spacing-20);height:var(--spacing-20);fill:var(--color-500);pointer-events:none;}
    .tc_form__clear{opacity:0;position:absolute;right:var(--form-input-label-padding);top:0;bottom:0;margin:auto;transition:opacity var(--transition-normal);pointer-events:none;}

    // label
    &:has(.tc_form__input){
      .tc_form__label{position:absolute;z-index:1;height:var(--form-input-label-height);left:calc(var(--form-input-label-padding) + var(--spacing-1));top:0;bottom:0;margin:var(--form-input-label-padding) 0 0;color:var(--color-600);transform-origin:0 0;transition:transform var(--transition-fast) var(--link-easing-hover), color var(--transition-fast), width var(--transition-fast);will-change:transform;cursor:text;font-size:var(--form-input-font-size);line-height:1.5;width:calc(100% - (2 * var(--form-input-label-padding)));white-space:nowrap;overflow:hidden;text-overflow:ellipsis;-webkit-user-select:none;user-select:none;text-align:left;pointer-events:none;
        &[data-floating-label="true"] + .tc_form__input{padding-top:var(--spacing-12);}
      }
    }
    // label focus / with entered text / autofilled
    &:has(.tc_form__input:focus,.tc_form__input[placeholder]:not(:placeholder-shown),.tc_form__input:-webkit-autofill){
      .tc_form__label[data-floating-label="true"]{transform:scale(calc(var(--form-input-label-scale) / 100)) translateY(-50%);width:calc((100% - (2 * var(--form-input-label-padding))) * (100 / var(--form-input-label-scale)));}
      .tc_form__label[data-floating-label="false"]{color:var(--color-400);}
      .tc_form__input::placeholder{opacity:1;}
    }
    // label focus with placeholder / label acting as placeholder / autofilled
    &:has(.tc_form__input[placeholder]:not([placeholder=" "]):focus,.tc_form__input[placeholder]:not(:placeholder-shown),.tc_form__input:-webkit-autofill){
      .tc_form__label[data-floating-label="false"]{opacity:0;}
    }
    // label and extra button with focus / with entered text / autofilled
    &:has(.tc_form__input--with-button:focus,.tc_form__input--with-button[placeholder]:not(:placeholder-shown),.tc_form__input--with-button:-webkit-autofill){
      .tc_form__label[data-floating-label="true"]{width:calc((100% - (2 * var(--form-input-label-padding)) - var(--spacing-40)) * (100 / var(--form-input-label-scale)));}
    }
    &:has(.tc_form__input[required]){
      .tc_form__label::after{content:" *";font-weight:var(--font-bold);}
    }
    &:has(.tc_form__input[type="date"],.tc_form__input[type="file"]){
      .tc_form__label[data-floating-label="true"]{transition:none;}
    }

    // search label (with icon)
    &:has(.tc_form__input[type="search"]){
      .tc_form__label{left:calc(var(--spacing-20) + var(--spacing-8) + var(--spacing-1) + var(--form-input-label-padding));width:calc(100% - (2 * var(--form-input-label-padding)) - var(--spacing-20) - var(--spacing-8));}
    }
    // search label and extra button
    &:has(.tc_form__input--with-button[type="search"]){
      .tc_form__label{width:calc(100% - (2 * var(--form-input-label-padding)) - var(--spacing-20) - var(--spacing-48));}
    }
    // search label focus / with entered text
    &:has(.tc_form__input[type="search"]:focus,.tc_form__input[type="search"][placeholder]:not(:placeholder-shown)){
      .tc_form__label[data-floating-label="true"]{width:calc((100% - (2 * var(--form-input-label-padding)) - var(--spacing-20) - var(--spacing-8)) * (100 / var(--form-input-label-scale)));}
    }
    // search label and extra button with focus / with entered text
    &:has(.tc_form__input--with-button[type="search"]:focus,.tc_form__input--with-button[placeholder][type="search"]:not(:placeholder-shown)){
      .tc_form__label[data-floating-label="true"]{width:calc((100% - (2 * var(--form-input-label-padding)) - var(--spacing-64)) * (100 / var(--form-input-label-scale)));}
    }
    // search label with entered text
    &:has(.tc_form__input[type="search"][placeholder]:not(:placeholder-shown)){
      .tc_form__clear{opacity:1;pointer-events:auto;}
    }
  }
  @supports not selector(:has(div)) {
    .tc_form__svg + .tc_form__label{left:calc(var(--spacing-20) + var(--spacing-8) + var(--spacing-1) + var(--form-input-label-padding));}
    .tc_form__input[type="search"]::-webkit-search-decoration{display:none;}
    .tc_form__input{-webkit-appearance:none;appearance:none;padding-top:var(--spacing-12);}
  }
  @media (scripting:none) {
    .tc_form__clear{display:none;}
  }
}

@include respond-to(hover) {
  :where(.tc_form__input){
    &:not(.tc_form__input--with-error):hover{border-color:var(--color-dark-600);}
    &[type="file"]:hover::file-selector-button{transition:color var(--transition-faster) var(--link-easing-hover);color:var(--color-link-hover);}
    &.tc_form__input--with-button:hover ~ .tc_form__button{background-color:var(--color-dark-100);}
  }
}
